// src/SpotifyAuth.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SpotifyAuth = ({ setSpotifyToken }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const hash = window.location.hash;
    console.log('Current URL hash:', hash); // Log the current URL hash

    if (hash) {
      const token = new URLSearchParams(hash.substring(1)).get('access_token');
      console.log('Retrieved Spotify token:', token); // Log the retrieved token

      if (token) {
        setSpotifyToken(token);
        navigate('/sessions');  // Redirect to player page after successful authentication
      }
    }
  }, [setSpotifyToken, navigate]);

  const handleLogin = () => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_REDIRECT_URI;
    const scopes = process.env.REACT_APP_SCOPES;
    const authUrl = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scopes)}`;
    window.location.href = authUrl;
  };

  return (
    <button onClick={handleLogin}>Log in with Spotify!</button>
  );
};

export default SpotifyAuth;
