import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SessionCreationModal from './SessionCreationModal';
import { useAuth } from '../../AuthContext';
import { Link } from 'react-router-dom';


const SessionExplorer = () => {
  const { authFetch } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  
  const navigate = useNavigate(); // Hook for navigation

  // // Assuming you store the token in local storage after user authentication
  // const token = localStorage.getItem('authToken');

  useEffect(() => {
    // Fetch all sessions
    const fetchSessions = async () => {
      try {
        const data = await authFetch('/sesiones/api/sesiones/');
        setSessions(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, [authFetch]); // Add authFetch as a dependency

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };


  const handleSaveSession = async (newSessionData) => {
    // console.log('holi');
    try {
      const savedSession = await authFetch('/sesiones/api/sesion/create/', {
        method: 'POST',
        body: newSessionData,
      });

      setSessions((prevSessions) => [...prevSessions, savedSession]); // Append the new session to the list
      // Navigate to the newly created session's detail view
      navigate(`/sesion/${savedSession.id}`);
    } catch (error) {
      console.error('Error saving session:', error);
    } finally {
      setModalOpen(false);
    }
  };
  

  // const handleSaveSession = (newSessionData) => {
  //   // Handle saving the session, possibly updating the session list dynamically
  //   console.log('Saving new session:', newSessionData);
  //   setModalOpen(false);
  //   // Add new session to the list of sessions
  //   setSessions((prevSessions) => [...prevSessions, newSessionData]);
  // };

  if (loading) {
    return <p>Loading sessions...</p>;
  }

  if (error) {
    return <p>Error fetching sessions: {error}</p>;
  }

  return (
    <div className="session-explorer">
      <h1>Available Sessions</h1>
      
      <button onClick={handleOpenModal}>Create New Session</button> {/* Button to open modal */}
      
      {sessions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Session Name</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map(session => (

                    
              <tr key={session.id}>
                <td colSpan="2" style={{ cursor: 'pointer' }}>
                  <Link to={`/sesion/${session.id}`} style={{ textDecoration: 'none', color: 'inherit', display: 'block', width: '100%', height: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span>{session.name}</span>
                      <span>{session.primera_fecha}</span>
                    </div>
                  </Link>
                </td>
              </tr>
                  
            ))}
          </tbody>
        </table>
      ) : (
        <p>No sessions available.</p>
      )}
      
      {/* Modal component */}
      <SessionCreationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveSession}
      />
    </div>
  );
};

export default SessionExplorer;
